module.exports = [{
      plugin: require('../node_modules/.pnpm/@chakra-ui+gatsby-plugin@3.1.3_@chakra-ui+react@2.8.2_@emotion+react@11.13.3_@types+react@18._ahgyacwf36jlwqwu7imvz5apz4/node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-fontawesome-css@1.2.0_@fortawesome+fontawesome-svg-core@6.5.2_gatsby@5.13.7_bab_inqhemte7meuhb6od3dyu2p36e/node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__5ew7aqhtbcntmgy4quedt5ipai/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
